(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAccount.controller:CompanyCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAccount')
    .controller('CompanyCtrl', CompanyCtrl);

  function CompanyCtrl(company, FormControl, $q, _, countries, $mdDialog, $document, $translate) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('account.company.updated');

    vm.company = company;
    vm.owner = _.first(company.owners);
    vm.countries = countries;

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer();

      vm.company.owners = [vm.owner];

      formControl
        .process(deferred.promise)
        .then(vm.openSaveAlert);

      vm.company.$update(deferred.resolve, deferred.reject);
    };

    vm.openSaveAlert = function openSaveAlert() {
      $mdDialog
        .show(
          $mdDialog
            .alert({focusOnOpen: false})
            .parent(angular.element($document.body))
            .clickOutsideToClose(true)
            .content($translate.instant('account.company.openAlert'))
            .ok($translate.instant('order.itemDialog.close'))
        );
    };
  }
}());
